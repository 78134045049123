import React from 'react';
import Layout from '../components/layout';
import Workshops from '../components/workshops';

class WorkshopsPage extends React.Component {

  render() {
    return (
      <Layout>
        <Workshops />
      </Layout>
    )
  }
}

export default WorkshopsPage;
