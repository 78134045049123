import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const Heading = styled.div`
  margin: 30px 0;
  text-align: center;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: larger;
  color: #000000;
`;

const StyledCol = styled(Col)`
  padding: 15px;
  border-top: 1px solid #f7941e;
`;

const ServiceTitle = styled.p`
  font-weight: bold;
  color: #000000;
`;

const ServiceContent = styled.div`
  margin-top: 15px;
  text-align: justify;
`;

const Workshops = () => (
    <Container fluid="true">
      <Heading>
        <Title>Workshops & Talks</Title>
      </Heading>
      <Row>
        <StyledCol sm={12} md={6} lg={6}>
            <ServiceTitle>Storytelling for Work</ServiceTitle>
            <ServiceContent>
              <p>A 3-hour workshop with 10-12 participants on how to become better at communicating ideas, 
              sales pitches, and business presentations with the help of storytelling.</p>
              <p>No one gets moved with the boring and bland way we communicate in the business world.
              You have to inspire and engage with stories to hold the attention of your audience, clients, and customers.</p>
              <p>This workshop imparts skills to be more persuasive, make interesting presentations,
              and get better at sales with the help of storytelling.</p>
            </ServiceContent>
        </StyledCol>
        <StyledCol sm={12} md={6} lg={6}>
            <ServiceTitle>Take It Easy</ServiceTitle>
            <ServiceContent>
              <p>A 1-hour talk on how to achieve bigger goals, become happier, and remain stress-free at work and in life.</p>
              <p>Happy people are more productive.</p>
              <p>The participants will learn how to be stress-free and happier under
              all circumstances leading to better team spirit, lesser conflicts.
              A more positive sustainable goal-oriented outlook towards work and
              life would translate to better relationships with colleagues and
              customers and unlock potential for achieving big goals.</p>
            </ServiceContent>
        </StyledCol>
      </Row>
    </Container>
)

export default Workshops
